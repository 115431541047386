import { useEffect, useState } from "react";
import style from "./filtro.module.css";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import Client from "../../pages/Datos/Clientes/Client";

const Filtro = (props) => {
    const { section } = props
    //Importations
    const dispatch = useDispatch()
    const { handleSubmit, control, trigger, reset, watch } = useForm()
    //States
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allClients = useSelector((state) => state.allClients)
    const allStores = useSelector((state) => state.allStores)
    const allTechnicals = useSelector((state) => state.allTechnicals)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [tecnicos,setTecnicos] = useState([])
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    let client = watch("clientId")
    const [actualClient, setActualClient] = useState("")
    useEffect(() => {
        if (!allDepartments || allDepartments.length === 0) {
            dispatch(actions.allDepartments())
        }
        if (!allCitys || allCitys.length === 0) {
            dispatch(actions.allCitys())
        }
        if (!allClients || allClients.length === 0) {
            dispatch(actions.allClients())
        }
        if (!allStores || allStores.length === 0) {
            dispatch(actions.allStores())
        }
        if (!allTechnicals || allTechnicals.length === 0) {
            dispatch(actions.allTechnical())
        }
    }, [])

    useEffect(() => {
        if (tecnicos.length === 0) {
            dispatch(actions.allTechnical())
            const uniqueTechnicals = Array.from(new Map(allTechnicals.map(tech => [tech.name.toLowerCase(), tech])).values());
            setTecnicos(uniqueTechnicals);
        }
    }, [allTechnicals, dispatch])
    const [actualSection, setActualSection] = useState("")
    useEffect(() => {
        setActualSection(section)
    }, [])
    useEffect(() => {
        setActualClient(client)
    }, [client])

    const handleChange = (e, allCitys) => {
        const departmentIdValue = e.target.value
        const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
        setFilterCity(citysById)
    }
    const handleChangeStore = (e, allStores) => {
        const cityIdValue = e.target.value
        const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }
    const clearFilter = () => {
        if (actualSection === "parts") {
            dispatch(actions.clearParts())
            reset()
        }
        if (actualSection === "stores") {
            dispatch(actions.clearStores())
            reset()
        }
        if (actualSection === "quotes") {
            dispatch(actions.clearQuotes())
            reset()
        }
        if (actualSection === "preBills") {
            dispatch(actions.clearPreBills())
            reset()
        }
        if (actualSection === "services") {
            dispatch(actions.clearServices())
            reset()
        }
        if (actualSection === "bills") {
            dispatch(actions.clearBills())
            reset()
        }
        if (actualSection === "reports") {
            dispatch(actions.clearReports())
            reset()
        }

    }

    const onSubmit = async (data) => {
        if (actualSection === "parts") {
            const dataSend = {
                cityId: data.cityId,
                subCategory: data.subCategory
            }
            dispatch(actions.filterActionParts(dataSend))
        }
        if (actualSection === "stores") {
            const dataSend = {
                cityId: data.cityId,
                clientId: data.clientId
            }
            dispatch(actions.filterActionStores(dataSend))
        }
        if (actualSection === "quotes") {
            const dataSend = {
                cityId: data.cityId,
                clientId: data.clientId,
                storeId: data.storeId
            }
            dispatch(actions.filterActionQuotes(dataSend))
        }
        if (actualSection === "preBills") {
            const dataSend = {
                cityId: data.cityId,
                clientId: data.clientId,
                storeId: data.storeId
            }
            dispatch(actions.filterActionPreBills(dataSend))
        }
        if (actualSection === "services") {
            const dataSend = {
                clientId: data.clientId,
                state: data.state,
                paymentState: data.paymentState
            }
            dispatch(actions.filterActionServices(dataSend))
        }
        if (actualSection === "bills") {
            const dataSend = {
                clientId: data.clientId,
                state: data.state,
                paymentState: data.paymentState
            }
            dispatch(actions.filterActionBills(dataSend))
        }
        if (actualSection === "reports") {
            const dataSend = {
                departmentId: data.departmentId,
                cityId: data.cityId,
                clientId: data.clientId,
                storeId: data.storeId,
                technicalId: data.technicalId
            }
            dispatch(actions.filterActionReports(dataSend))
        }
    }
    return (
        <div>
            <div className={style.contenedor}>
                <div className={style.filtro}>
                    <label className={style.label}> Filtros </label>
                    <label className={`${style.switch} ${isChecked ? style.checked : ''}`}>
                        <input type="checkbox" onChange={handleToggle} />
                        <span className={style.slider}></span>
                    </label>
                </div>
                {isChecked === true ? (
                    <div>
                        {actualSection === "parts" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="subCategory"> Categoria </label>
                                            <div className={style.divInput}>
                                                <Controller name="subCategory"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("subCategory") }}>
                                                            <option value="" disabled> Seleccione la categoría </option>
                                                            <option value="Iluminación" > Iluminación </option>
                                                            <option value="Refrigeración" > Refrigeración </option>
                                                            <option value="Limpieza" > Limpieza </option>
                                                            <option value="Otro" > Otro </option>
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                            <div className={style.divInput}>
                                                <Controller name="departmentId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                            <option value="" disabled> Seleccione el departamento </option>
                                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.department}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                            <div className={style.divInput}>
                                                <Controller name="cityId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); }}>
                                                            <option value="" disabled> Seleccione la ciudad </option>
                                                            {filterCity.map((city) => (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.city}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "stores" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                            <div className={style.divInput}>
                                                <Controller name="departmentId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                            <option value="" disabled> Seleccione el departamento </option>
                                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.department}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                            <div className={style.divInput}>
                                                <Controller name="cityId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); }}>
                                                            <option value="" disabled> Seleccione la ciudad </option>
                                                            {filterCity.map((city) => (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.city}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "quotes" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                            <div className={style.divInput}>
                                                <Controller name="departmentId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                            <option value="" disabled> Seleccione el departamento </option>
                                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.department}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                            <div className={style.divInput}>
                                                <Controller name="cityId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                            <option value="" disabled> Seleccione la ciudad </option>
                                                            {filterCity.map((city) => (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.city}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="storeId"> Almacen </label>
                                            <div className={style.divInput}>
                                                <Controller name="storeId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); }}>
                                                            <option value="" disabled> Seleccione el almacen </option>
                                                            {filterStore.map((store) => (
                                                                <option key={store.id} value={store.id}>
                                                                    {store.name}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "preBills" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                            <div className={style.divInput}>
                                                <Controller name="departmentId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                            <option value="" disabled> Seleccione el departamento </option>
                                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.department}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                            <div className={style.divInput}>
                                                <Controller name="cityId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                            <option value="" disabled> Seleccione la ciudad </option>
                                                            {filterCity.map((city) => (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.city}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="storeId"> Almacen </label>
                                            <div className={style.divInput}>
                                                <Controller name="storeId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); }}>
                                                            <option value="" disabled> Seleccione el almacen </option>
                                                            {filterStore.map((store) => (
                                                                <option key={store.id} value={store.id}>
                                                                    {store.name}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "services" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="state"> Estado </label>
                                            <div className={style.divInput}>
                                                <Controller name="state"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                                            <option value="" disabled> Seleccione el estado </option>
                                                            <option value={true}> Activo </option>
                                                            <option value={false}> Cerrado </option>
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="paymentState"> Estado de pago </label>
                                            <div className={style.divInput}>
                                                <Controller name="paymentState"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("paymentState"); }}>
                                                            <option value="" disabled> Seleccione el pago </option>
                                                            <option value={true} > Confirmado </option>
                                                            <option value={false} > Pendiente </option>
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "bills" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="state"> Estado </label>
                                            <div className={style.divInput}>
                                                <Controller name="state"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                                            <option value="" disabled> Seleccione el estado </option>
                                                            <option value={true}> Activo </option>
                                                            <option value={false}> Cerrado </option>
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="paymentState"> Estado de pago </label>
                                            <div className={style.divInput}>
                                                <Controller name="paymentState"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("paymentState"); }}>
                                                            <option value="" disabled> Seleccione el pago </option>
                                                            <option value={true} > Confirmado </option>
                                                            <option value={false} > Pendiente </option>
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        {actualSection === "reports" ? (
                            <div className={style.newUser}>
                                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                                    <div className={style.divDatos}>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="clientId"> Cliente </label>
                                            <div className={style.divInput}>
                                                <Controller name="clientId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el cliente </option>
                                                            {allClients !== undefined ? allClients.map((client) => (
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                            <div className={style.divInput}>
                                                <Controller name="departmentId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                            <option value="" disabled> Seleccione el departamento </option>
                                                            {allDepartments !== undefined ? allDepartments.map((department) => (
                                                                <option key={department.id} value={department.id}>
                                                                    {department.department}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                            <div className={style.divInput}>
                                                <Controller name="cityId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                            <option value="" disabled> Seleccione la ciudad </option>
                                                            {filterCity.map((city) => (
                                                                <option key={city.id} value={city.id}>
                                                                    {city.city}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="storeId"> Almacen </label>
                                            <div className={style.divInput}>
                                                <Controller name="storeId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); }}>
                                                            <option value="" disabled> Seleccione el almacen </option>
                                                            {filterStore.map((store) => (
                                                                <option key={store.id} value={store.id}>
                                                                    {store.name}</option>))}
                                                        </select>)} />
                                            </div>
                                        </div>
                                        <div className={style.divCampo}>
                                            <label className={style.label} htmlFor="technicalId"> Técnico </label>
                                            <div className={style.divInput}>
                                                <Controller name="technicalId"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={({ field }) => (
                                                        <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId") }}>
                                                            <option value="" disabled> Seleccione el técnico</option>
                                                            {tecnicos !== undefined ? tecnicos.map((client) => (
                                                                client.authorized &&
                                                                <option key={client.id} value={client.id}>
                                                                    {client.name + " " + client.surname}</option>)) : null}
                                                        </select>)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.divBotones}>
                                        <button type="submit" className={style.formbutton}><img className={style.icon} src="https://api.iconify.design/material-symbols:manage-search.svg?color=%23313372" alt="editbuttom" /></button>
                                        <button type="button" className={style.backbutton} onClick={() => clearFilter()}><img className={style.icon} src="https://api.iconify.design/ic:baseline-clear.svg?color=%23313372" alt="editbuttom" /></button>
                                    </div>
                                </form>
                            </div>) : (null)}
                        
                    </div>) : (null)}
            </div>
        </div>
    )
}

export default Filtro;
