import style from "./createpreBill.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const UpdatePreBill = () => {
    // Importations
    const dispatch = useDispatch()
    const { id } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const navigate = useNavigate()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [quote, setQuote] = useState({})
    // Get de quote
    const getActualQuote = async (preBillsId) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'get',
                url: `${url}/preBills/${preBillsId}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    const preBill = response.data.byId
                    setActualQuote(preBill)
                    setQuoteList(preBill.quoteParts)
                }
            }
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    useEffect(() => {
        getActualQuote(id)
    }, [dispatch])
    useEffect(() => {
        setValue('reportId', actualQuote.reportId === 0 ? ("") : (actualQuote.reportId))
        setValue('quoteId', actualQuote.quoteId);
        setValue('reportRum', actualQuote.reportRum);
        setValue('actaEntrega', actualQuote.actaEntrega);
        setValue("paymentMeth", actualQuote.paymentMeth)
        setValue("date", actualQuote.date)
        setValue("clientId", actualQuote.clientId)
        setValue("cityId", actualQuote.cityId)
        setValue("storeId", actualQuote.storeId)
    }, [actualQuote, setValue]);
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
        setCountItem(maxItem + 1);
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
    }, [dispatch])
    useEffect(() => {
        setActualClient(client)
    }, [client])
    // Funciones
    const sumOfTotalPrice = quoteList.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.totalCost;
    }, 0);
    const handlePartChange = (e) => {
        setSelectedPart(e.target.value);
    };
    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };
    const handleServiceChange = (e) => {
        setServiceDescription(e.target.value);
    };
    const handlePriceChange = (e) => {
        setServicePrice(e.target.value);
    };
    const addPartToQuote = () => {
        const partFilter = allParts.filter((part) => part.id === parseInt(selectedPart));

        if (partFilter.length > 0) { // Verifica que se haya encontrado un elemento
            const part = {
                item: countItem,
                idPart: parseInt(selectedPart),
                description: partFilter[0].name + " " + partFilter[0].description,
                unidad: "UND",
                quantity: parseInt(quantity),
                costUnit: partFilter[0].price,
                totalCost: partFilter[0].price * quantity
            };
            quoteList.push(part)
            setCountItem(countItem + 1)
        }
    };
    const addServiceToQuote = () => {
        const service = {
            item: countItem,
            description: serviceDescription,
            unidad: "UND",
            quantity: 1,
            costUnit: parseInt(servicePrice),
            totalCost: servicePrice * 1
        };
        quoteList.push(service)
        setCountItem(countItem + 1)
    }
    const onSubmit = async (data) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'patch',
                url: `${url}/preBills/update/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    navigate("/preBills");
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear tienda:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    const verifyQuote = async (id) => {
        try {
            const config = {
                method: 'get',
                url: `${url}/quotes/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            };
            const res = await axios(config);
            const { status } = res;
            if (status === 201) {
                return true
            }
            else {
                return false
            }
        } catch (error) {

            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
            return error.message;
        }
    }

    const handleGoBack = () => {
        navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
    };
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>PreFacturas</h1>
                <h4 className={style.subtitulo}>Actualizar prefactura # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportId">Número de reporte</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportId"
                                            control={control}
                                            defaultValue=""
                                            disabled
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("reportId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportId && <p className={style.errorText}>{errors.reportId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="quoteId">Número de cotización</label>
                                    <div className={style.divInput}>
                                        <Controller name="quoteId"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' },
                                                validate: async (value) => {
                                                    try {
                                                        const success = await verifyQuote(value);
                                                        if (success === true) {
                                                            return true;
                                                        } else {
                                                            return "Cotización inexistente";
                                                        }
                                                    } catch (error) {
                                                        console.error("Error al verificar el correo:", error);
                                                        return "Error al verificar el correo";
                                                    }
                                                }
                                            }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("quoteId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.quoteId && <p className={style.errorText}>{errors.quoteId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportRum">RUM</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportRum"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("reportRum"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportRum && <p className={style.errorText}>{errors.reportRum.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="actaEntrega">Acta de entrega</label>
                                    <div className={style.divInput}>
                                        <Controller name="actaEntrega"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 50, message: 'Máximo 50 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("actaEntrega"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.actaEntrega && <p className={style.errorText}>{errors.actaEntrega.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="date">Fecha</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={style.divDatos}>
                            <label className={style.label}>Añadir repuestos</label>
                            <div className={style.divCampo}>
                            <label className={style.label}>Repuesto</label>
                            <div className={style.divInput}>
                                <select defaultValue="" className={style.select} onChange={(e) => {handlePartChange(e)}}>
                                <option value="" disabled> Seleccione el repuesto </option>
                                {allParts !== undefined && allParts.map((store) => (
                                        <option key={store.id} value={store.id}>
                                            {store.name}</option>))}
                                </select>
                            </div>
                            </div>
                            <div className={style.divCampo}>
                            <label className={style.label}>Cantidad</label>
                            <div className={style.divInput}>
                            <input className={style.input} maxLength={10} type="number" onChange={(e) => {handleQuantityChange(e)}}/>
                            </div>
                            </div>
                            <button className={style.formbutton} type="button" onClick={addPartToQuote} disabled={quantity==="" || selectedPart === ""}>Añadir repuesto</button>
                    </div>
                    <div className={style.divDatos}>
                    <label className={style.label}>Añadir servicio</label>
                    <div className={style.divCampo}>
                            <label className={style.label}>Servicio</label>
                            <div className={style.divInput}>
                            <textarea className={style.input} rows={Math.ceil(150/30)} maxLength={150} type="text" onChange={(e) => {handleServiceChange(e)}}/>
                            </div>
                    </div>
                    <div className={style.divCampo}>
                            <label className={style.label}>Precio</label>
                            <div className={style.divInput}>
                            <input className={style.input} maxLength={10} type="number" placeholder="COP $" onChange={(e) => {handlePriceChange(e)}}/>
                            </div>
                    </div>
                    <button className={style.formbutton} onClick={addServiceToQuote} type="button" disabled={serviceDescription === "" || servicePrice ===""} >Añadir servicio</button>
                    </div> 
                    </div>
                    <div className={style.divDatos}>
                        <h3 className={style.subtitulo}>Resumen de cotización</h3>
                        <div className={style.divTabla}>
                            <table className={style.table}>
                                <tr className={style.tr}> 
                                <th className={style.th}>Item</th>
                                <th className={style.th}>Descripción</th>
                                <th className={style.th}>Unidad</th>
                                <th className={style.th}>Cantidad</th>
                                <th className={style.th}>Valor unitario</th>
                                <th className={style.th}>Valor total</th>
                                <th className={style.th}>Acciones</th>
                                </tr>
                                {quoteList.map((orden) => (
                                <tr className={style.tr} key={orden.item}>
                                    <td className={style.td}>{orden.item}</td>
                                    <td className={style.td}>{orden.description}</td>
                                    <td className={style.td}>{orden.unidad}</td>
                                    <td className={style.td}>{orden.quantity}</td>
                                    <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                                    <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                                    <td className={style.tdetail}> <button type="button" className={style.deleteItem} onClick={() => removeItemById(quoteList, orden.item)}> Eliminar </button> </td>
                                </tr>))}
                            </table>
                        </div> */}
                        </div>
                        <button type="submit" className={style.formbutton} disabled={quoteList.length < 1} >Actualizar prefactura</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Prefactura actualizada con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default UpdatePreBill





// const handleChange = (e, allCitys) => {
//     const departmentIdValue = e.target.value
//     const citysById = allCitys.filter((city) => city.departmentId === parseInt(departmentIdValue))
//     setFilterCity(citysById)
// }
// const handleChangeStore = (e, allStores) => {
//     const cityIdValue = e.target.value
//     const storesById = allStores.filter((store) => store.cityId === parseInt(cityIdValue))
//     const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
//     setFilterStore(storesByClient)
// }
// const handleChangeClientId = (e) => {
//     setValue('departmentId', "");
//     trigger('departmentId');
//     setValue('cityId', "");
//     trigger('cityId');
//     setValue('storeId', "");
//     trigger('storeId');
//   };