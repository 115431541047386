import style from "./viewReport.module.css";
import NavBar from "../../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { isBefore, parseISO } from "date-fns";
import * as actions from "../../../redux/actions";
import axios from "axios";
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const ViewReport = () => {
  // Importations
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    reset,
    watch,
    register,
    setValue,
  } = useForm();
  // Global States
  const userData = useSelector((state) => state.userLoginNow);
  const allClients = useSelector((state) => state.allClients);
  const allParts = useSelector((state) => state.allParts);
  const allCitys = useSelector((state) => state.allCitys);
  const allDepartments = useSelector((state) => state.allDepartments);
  const allStores = useSelector((state) => state.allStores);
  const allTechnicals = useSelector((state) => state.allTechnicals);
  const alertSession = useSelector((state) => state.alertSession);
  let client = watch("clientId");
  // Local states
  const [formDisabled, setFormDisabled] = useState(null);
  const [filterCity, setFilterCity] = useState([]);
  const [filterStore, setFilterStore] = useState([]);
  const [actualClient, setActualClient] = useState("");
  const [selectedPart, setSelectedPart] = useState("");
  const [quantity, setQuantity] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [quoteList, setQuoteList] = useState(null);
  const [countItem, setCountItem] = useState(1);
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [actualQuote, setActualQuote] = useState({});
  const [quote, setQuote] = useState({});
  const [tecnicos, setTecnicos] = useState([]);
  const [actual, setActual] = useState({});
  // Get de quote
  const getActualQuote = async (quoteId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: "get",
        url: `${url}/reports/${quoteId}`,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios(config);
      if (response.data) {
        if (response.data.success === true) {
          const quote = response.data.report;
          setActualQuote(quote);
          if (quote.quoteParts) {
            setQuoteList(quote.quoteParts);
          }
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false);

      if (error.response.status === 401) {
        dispatch({
          type: ALERT_SESSION,
          payload: true,
        });

        setTimeout(() => {
          localStorage.clear("globalState");
          window.location.replace(`${urlFront}/inicio`);
        }, [3000]);
      }
    }
  };
  useEffect(() => {
    getActualQuote(id);
  }, [dispatch]);
  useEffect(() => {
    if (tecnicos.length === 0) {
      dispatch(actions.allTechnical());
      setTecnicos(allTechnicals);
    }
  }, [dispatch, allTechnicals]);
  useEffect(() => {
    // Calcula el máximo valor de 'item'
    if (quoteList !== null) {
      const maxItem = Math.max(...quoteList.map((obj) => obj.item), 0);
      setCountItem(maxItem + 1);
    }
  }, [quoteList]);
  //UseEffect
  useEffect(() => {
    dispatch(actions.allStores());
    dispatch(actions.allClients());
    dispatch(actions.allParts());
  }, [dispatch]);
  useEffect(() => {
    setActualClient(client);
  }, [client]);
  useEffect(() => {
    handleChangeTech(actualQuote.technician, tecnicos);
  }, [actualQuote, dispatch, tecnicos]);
  // Funciones
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : "Cliente desconocido";
  };
  const getStoreName = (allStores, storeId) => {
    const store = allStores.find((store) => store.id === storeId);
    return store ? store.name : "Tienda desconocido";
  };
  const getCityName = (allCitys, cityId) => {
    const city = allCitys && allCitys.find((city) => city.id === cityId);
    return city ? city.city : "Ciudad Desconocida";
  };
  async function convertImageToBase64(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Asegúrate de que el resultado es un Data URL completo adecuado para uso directo como imagen
          resolve(reader.result);
        };
        reader.onerror = () =>
          reject(new Error("Failed to convert blob to base64"));
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw new Error(`Error fetching image: ${error.message}`);
    }
  }

  const handleChangeTech = (id, tecnicos) => {
    const tecnicoActual = tecnicos.find((tecnico) => tecnico.id === id);
    setActual(tecnicoActual);
    return true;
  };
  const handleCollageChange = async (elements) => {
    if (elements.length > 0) {
      try {
        const formData = new FormData();
        const assets = {
          template: "grid",
          width: 600,
          height: 600,
          columns: 2,
          rows: Math.ceil(elements.length / 2),
          spacing: 1,
          color: "black",
          assetDefaults: { kind: "upload", crop: "fill", gravity: "center" },
          assets: Array.from(
            { length: 2 * Math.ceil(elements.length / 2) },
            (_, i) => ({ media: elements[i % elements.length].publicid })
          ),
        };
        formData.append("resource_type", "image");
        formData.append("upload_preset", "lz8ce4ss");
        formData.append("manifest_json", JSON.stringify(assets));
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dldkowbgc/image/create_collage",
          {
            method: "POST",
            body: formData,
          }
        );
        if (response.ok) {
          // Verifica que la respuesta fue exitosa (código de estado HTTP 200-299)
          const data = await response.json(); // Obtiene los datos de la respuesta como JSON
          return data.public_id;
        } else {
          console.log(response);
          throw new Error(
            "Error " + response.status + ": " + response.statusText
          );
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    }
  };
  const handleGeneratePDF = async (data) => {
    const urldata = url;
    try {
      data.clientId = getClientName(allClients, data.clientId);
      data.storeId = getStoreName(allStores, data.storeId);
      data.state = data.state === true ? "Activo" : "Cerrado";
      data.stateMachine = data.stateMachine === true ? "Sí" : "No";
      data.quoteState = data.stateMachine === true ? "Sí" : "No";
      data.date = data.date && data.date.split("T")[0];
      data.technician = actual.name + " " + actual.surname;
      data.id = data.id;
      data.priority =
        data.priority === null ||
        data.priority === undefined ||
        data.priority === ""
          ? "Sin prioridad, no se requiere repuestos"
          : data.priority;
      async function fetchPdf(pdfId) {
        const config = {
          method: "get",
          url: `https://apirefriactive.tecde.co/reports/collage/${pdfId}`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        try {
          const response = await axios(config);
          if (response.status === 200 && response.data) {
            return response.data.pdf;
          } else {
            console.log(
              "Error con la respuesta del servidor: Status",
              response.status
            );
            return undefined;
          }
        } catch (error) {
          console.error("Error en la solicitud:", error);

          if (error.response.status === 401) {
            dispatch({
              type: ALERT_SESSION,
              payload: true,
            });

            setTimeout(() => {
              localStorage.clear("globalState");
              window.location.replace(urlFront);
            }, [3000]);
          }
          return undefined;
        }
      }
      let valor = undefined;
      if (data.pdf !== null) {
        valor = await fetchPdf(data.pdf);
        data.imagen = await convertImageToBase64(valor);
        const config = {
          method: "get",
          url: `${urldata}/quotes/generate`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const res = await axios(config);
        const apiKey = res.data.respuesta;
        const response = await fetch("https://api.prexview.com/v1/transform", {
          method: "POST",
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: JSON.stringify(data),
            template: "refriactivereports",
            output: "pdf",
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Reporte #${data.id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (data.pdf === null && data.base === null) {
        const config = {
          method: "get",
          url: `${urldata}/quotes/generate`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const res = await axios(config);
        const apiKey = res.data.respuesta;
        const response = await fetch("https://api.prexview.com/v1/transform", {
          method: "POST",
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: JSON.stringify(data),
            template: "refriactivereports",
            output: "pdf",
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Reporte #${data.id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (data.base !== null && data.pdf === null) {
        data.pdf = await handleCollageChange(actualQuote.base);
        setTimeout(async () => {
          if (data.pdf !== null) {
            valor = await fetchPdf(data.pdf);
          }
          data.imagen = await convertImageToBase64(valor);
          const config = {
            method: "get",
            url: `${urldata}/quotes/generate`,
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          };
          const res = await axios(config);
          const apiKey = res.data.respuesta;
          const response = await fetch(
            "https://api.prexview.com/v1/transform",
            {
              method: "POST",
              headers: {
                Authorization: apiKey,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                json: JSON.stringify(data),
                template: "refriactivereports",
                output: "pdf",
              }),
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `Reporte #${data.id}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, 10000);
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response.status === 401) {
        dispatch({
          type: ALERT_SESSION,
          payload: true,
        });

        setTimeout(() => {
          localStorage.clear("globalState");
          window.location.replace(urlFront);
        }, [3000]);
      }
    }
  };
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  };

  function calcularDuracion(timeIn, timeOut) {
    if (timeIn != undefined && timeOut != undefined) {
      function convertFormat12to24(timer) {
        const [time, modifier] = timer.split(" ");
        let [hours, minutes, seconds] = time.split(":").map(Number);
        if (modifier === "PM" && hours !== 12) {
          hours += 12;
        } else if (modifier === "AM" && hours === 12) {
          hours = 0;
        }
        seconds = seconds === undefined ? 0 : seconds;
        return { hours, minutes, seconds };
      }

            // Crear objetos Date para hoy con los tiempos especificados
            const fechaBase = new Date().toISOString().substring(0, 10); // Obtiene la fecha de hoy
  
            const { hours: hoursIn, minutes: minutesIn, seconds: secondsIn } = convertFormat12to24(timeIn);
            const { hours: hoursOut, minutes: minutesOut, seconds: secondsOut } = convertFormat12to24(timeOut);
            
            let dateTimeIn = new Date(`${fechaBase}T${hoursIn.toString().padStart(2, '0')}:${minutesIn.toString().padStart(2, '0')}:${secondsIn.toString().padStart(2, '0')}`);
            let dateTimeOut = new Date(`${fechaBase}T${hoursOut.toString().padStart(2, '0')}:${minutesOut.toString().padStart(2, '0')}:${secondsOut.toString().padStart(2, '0')}`);
        
            // Calcular la diferencia en milisegundos
            let diferencia = dateTimeOut - dateTimeIn;
        
            // Si la diferencia es negativa, significa que timeOut fue antes de timeIn, entonces asumimos que timeOut es del día siguiente
            if (diferencia < 0) {
                dateTimeOut = new Date(dateTimeOut.getTime() + 86400000); // Agrega 24 horas en milisegundos
                diferencia = dateTimeOut - dateTimeIn;
            }
        
            // Convertir la diferencia de milisegundos a horas, minutos y segundos
            const horas = Math.floor(diferencia / 3600000); // 3600000 milisegundos en una hora
            const minutos = Math.floor((diferencia % 3600000) / 60000); // 60000 milisegundos en un minuto
            const segundos = Math.floor((diferencia % 60000) / 1000); // 1000 milisegundos en un segundo
        
            return `${horas}h:${minutos}min:${segundos}s`;
        } else {
            return "-"
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Reportes</h1>
                <h4 className={style.subtitulo}>Reporte # {actualQuote.id}</h4>
                <div className={style.divForm}>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Número RUM</label>
                            <p className={style.input}> {actualQuote.rum}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Fecha de solicitud</label>
                            <p className={style.input}> {actualQuote.date && actualQuote.date.split('T')[0]}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Hora de entrada</label>
                            <p className={style.input}> {actualQuote.timeIn}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Hora de salida</label>
                            <p className={style.input}> {actualQuote.timeOut}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Duración del servicio</label>
                            <p className={style.input}> {actualQuote && calcularDuracion(actualQuote.timeIn, actualQuote.timeOut)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Tipo de servicio</label>
                            <p className={style.input}> {actualQuote.serviceType}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Cliente</label>
                            <p className={style.input}>{getClientName(allClients, actualQuote.clientId)}</p>
                        </div>
                    </div>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>Ciudad</label>
                            <p className={style.input}>{getCityName(allCitys, actualQuote.cityId)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Almacen</label>
                            <p className={style.input}>{getStoreName(allStores, actualQuote.storeId)}</p>
                            <p className={style.input}>{`Almacen # ${actualQuote.storeId}`}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Quien recibe</label>
                            <p className={style.input}> {actualQuote.received && actualQuote.received.name}</p>
                            <p className={style.input}> C.C. {actualQuote.received && actualQuote.received.identification}</p>
                            <p className={style.input}> {actualQuote.received && actualQuote.received.cargo}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Correo electrónico</label>
                            <p className={style.input}> {actualQuote.received && actualQuote.received.email}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Numero de activo</label>
                            <p className={style.input}>{actualQuote.activeNumber}</p>
                        </div>
                    </div>
                    <div className={style.divDatos}>
                        <div className={style.divCampo}>
                            <label className={style.label}>El equipo queda operando </label>
                            <p className={style.input}>{actualQuote.stateMachine === true ? ("Sí") : ("No")}</p>
                        </div>
                        <div className={style.divCampo}>
                            <div className={style.divCampo}>
                                <label className={style.label}>Estado del servicio </label>
                                <p className={style.input}>{actualQuote.state === true ? ("Abierto") : ("Cerrado")}</p>
                            </div>
                            <label className={style.label}>Técnico</label>
                            <p className={style.input}>{(actual !== undefined ? actual.name + " " + actual.surname + `${actual.authorized ? "" : "(inactivo)"}` : null)}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Suministra repuestos </label>
                            <p className={style.input}>{actualQuote.suministra === "" ? "No" : actualQuote.suministra}</p>
                        </div>
                        <div className={style.divCampo}>
                            <label className={style.label}>Prioridad de repuestos </label>
                            <p className={style.input}>{actualQuote.priority === "" ? "No" : actualQuote.priority}</p>
                        </div>
                    </div>
                </div>
                <div className={style.divCampo}>
                    <label className={style.label}>Observaciones</label>
                    <p className={style.inputObservations}>{actualQuote !== undefined && actualQuote.observations === null ? "Sin observaciones" : actualQuote.observations}</p>
                </div>
                <div className={style.divDatos}>
                    <h3 className={style.subtitulo}>Repuestos de reporte</h3>
                    <div className={style.divTabla}>
                        <table className={style.table}>
                            <tr className={style.tr}>
                                <th className={style.th}>Item</th>
                                <th className={style.th}>Descripción</th>
                                <th className={style.th}>Cantidad</th>
                                <th className={style.thres}>Descripción</th>
                                <th className={style.thres}>Cantidad</th>
                            </tr>
                            {quoteList !== null && quoteList.length >= 1 && quoteList.map((orden) => (
                                <tr className={style.tr} key={orden.item}>
                                    <td className={style.td}>{orden.item}</td>
                                    <td className={style.td}>{orden.description}</td>
                                    <td className={style.td}>{orden.quantity}</td>
                                    <td className={style.tdres}>{orden.description}</td>
                                    <td className={style.tdres}>{orden.quantity}</td>
                                </tr>))}
                        </table>
                    </div>
                </div>
                <button type="button" className={style.pdfbutton} onClick={() => handleGeneratePDF(actualQuote)} disabled={actualQuote === undefined || actualQuote.id === undefined}>Descargar PDF</button>
                <button type="button" className={style.backbutton} onClick={handleGoBack}>Volver</button>
                <Link to={`/reports/update/${actualQuote.id}`}>
                    <button type="button" className={style.backbutton}>Editar reporte</button>
                </Link>
                <div className={style.divCampo}>
                    <label className={style.label}>Evidencias fotográficas</label>
                    <div className={style.divImag}>
                        {actualQuote.collage && Object.values(actualQuote.collage).map((imageUrl, index) => (
                            <img
                                key={index}
                                src={imageUrl}
                                alt={`Evidencia fotográfica ${index + 1}`}
                                className={style.imagenEvidencia}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ViewReport
