import style from "./createcount.module.css"
import NavBar from "../../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../../App";
import { ALERT_SESSION } from "../../../../redux/action-types";
import AlertSession from "../../../../components/AlertSession/AlertSession";


const CreateCount = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue, getValues } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allSuppliers = useSelector((state) => state.allSuppliers)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let state = watch("state")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterCity, setFilterCity] = useState([])
    const [filterStore, setFilterStore] = useState([])
    const [actualState, setActualState] = useState("")
    const [selectedPart, setSelectedPart] = useState("");
    const [quantity, setQuantity] = useState("");
    const [quantityServ, setQuantityServ] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [subtotal, setSubtotal] = useState('');
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
        dispatch(actions.allSuppliers())
    }, [dispatch])
    useEffect(() => {
        setActualState(state)
    }, [state])

    // Funciones
    const onSubmit = async (data) => {
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/counts/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: data
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    setConfirmRegistro(true)
                }
                else {
                    setConfirmRegistro(false)
                }
            }
            setTimeout(() => {
                setConfirmRegistro(null);
                reset();
                setFormDisabled(false);
                setTimeout(() => {
                    navigate("/accounting/counts");
                }, 100);
            }, 2000);
        } catch (error) {
            console.error("Error al crear cuenta por pagar:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }

    const applyIVA = () => {
        const valor =  getValues('valor')
        setValue("iva", valor * 0.19)
        setValue("subtotal", parseInt(valor) + (parseInt(valor) * 0.19))
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Cuentas por pagar</h1>
                <h4 className={style.subtitulo}>Crear cuenta por pagar</h4>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="supplierId"> Proovedor </label>
                                    <div className={style.divInput}>
                                        <Controller name="supplierId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'Seleccione el proveedor' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("supplierId") }}>
                                                    <option value="" disabled> Seleccione el proveedor </option>
                                                    {allSuppliers !== undefined ? allSuppliers.map((client) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.name}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.supplierId && <p className={style.errorText}>{errors.supplierId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="valor">Valor de la cuenta</label>
                                    <div className={style.divInput}>
                                        <Controller name="valor"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 30, message: 'Maximo 30 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("valor"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.valor && <p className={style.errorText}>{errors.valor.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <button type="button" className={style.backbutton} onClick={() => applyIVA()}>Aplicar IVA</button>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="iva">IVA</label>
                                    <div className={style.divInput}>
                                        <Controller name="iva"
                                            control={control}
                                            defaultValue={0}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("iva"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.iva && <p className={style.errorText}>{errors.iva.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="subtotal">Subtotal con IVA</label>
                                    <div className={style.divInput}>
                                        <Controller name="subtotal"
                                            control={control}
                                            defaultValue={subtotal || 0}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("subtotal"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.subtotal && <p className={style.errorText}>{errors.subtotal.message}</p>}
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="date">Fecha de registro</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="expirationDate">Fecha de vencimiento</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="expirationDate"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("expirationDate"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.expirationDate && <p className={style.errorText}>{errors.expirationDate.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="state">Estado de Pago</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="state"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: 'Seleccione su estado de pago' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("state"); }}>
                                                    <option value="" disabled>Seleccione el estado</option>
                                                    <option value={true}>Pagado</option>
                                                    <option value={false}>Pendiente</option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.state && <p className={style.errorText}>{errors.state.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                {actualState === "true" && <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentDate">Fecha de pago efectuado</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="paymentDate"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentDate && <p className={style.errorText}>{errors.paymentDate.message}</p>}
                                        </div>
                                    </div>
                                </div>}
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="description"> Número de factura </label>
                                    <div className={style.divInput}>
                                        <Controller name="description"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: { value: 100, message: 'Maximo 100 caracteres' } }}
                                            render={({ field }) => (
                                                <textarea className={style.input} rows={1} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("description"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.description && <p className={style.errorText}>{errors.description.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="comments"> Comentarios/descripcion </label>
                                    <div className={style.divInput}>
                                        <Controller name="comments"
                                            control={control}
                                            defaultValue=""
                                            rules={{ maxLength: { value: 100, message: 'Maximo 100 caracteres' } }}
                                            render={({ field }) => (
                                                <textarea className={style.input} rows={Math.ceil(100 / 30)} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("comments"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.comments && <p className={style.errorText}>{errors.comments.message}</p>}
                                        </div>
                                    </div>
                                </div>
                        <button type="submit" className={style.formbutton} >Crear cuenta por pagar</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Cuenta por pagar creada con exito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/accounting/counts">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreateCount