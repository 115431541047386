// Actions imports
// Users actions
import { loginUser, existUser, logoutUser, setUserDatas, setLoginStatu, setLoginReport, getAllUsers, loginReportAccess } from "./actions/userActions"
// Clients actions
import { getAllClients } from "./actions/clientActions"
// Parts actions
import { clearFilterParts, getAllParts, getFilterParts, getSearchParts } from "./actions/partsActions"
// Stores actions
import { clearFilterStores, getAllStores, getFilterStore, getSearchStores } from "./actions/storeActions"
// Quotes actions
import { getAllQuotes, getFilterQuotes, clearFilterQuotes, getSearchQuotes } from "./actions/quotesActions"
// PreBills actions
import { getAllPreBills, getFilterPreBills, getSearchPreBills, clearFilterPreBills} from "./actions/preBillsActions"
// Services actions
import {getAllServices, getFilterServices, getSearchServices, clearFilterServices, getSearchServicesbyQuoteandPrebill} from "./actions/servicesActions"
//Bills actions 
import {getAllBills, getFilterBills, getSearchBills, clearFilterBills} from "./actions/billsActions"
//Reports actions 
import {getAllReports, getFilterReports, getSearchReports, clearFilterReports} from "./actions/reportsActions"
//Suppliers actions 
import {getAllSuppliers, clearFilterSuppliers, getSearchSuppliers, getFilterSuppliers} from "./actions/supplierActions"
//Counts actions
import {getAllCounts, getFilterCounts, getSearchCounts, clearFilterCounts} from "./actions/countsActions"
// Demography actions
import { getCitys, getDepartments } from "./actions/demoActions"
// Technical actions
import { getAllTechincal, getTechincalById, logoutTechnical } from "./actions/TechActions"
// Incomes actions
import { getAllIncomes, getFilterIncomes, clearFilterIncomes } from "./actions/IncomesActions"
// Bank actions
import { getAllBanks, getFilterBanks, clearFilterBanks } from "./actions/bankActions"


// Actions declaration
// Users actions
export const login = loginUser
export const exist = existUser
export const logout = logoutUser
export const setUserData = setUserDatas
export const setLoginStatus = setLoginStatu
export const setLoginReportStatus = setLoginReport
export const allUsers = getAllUsers
export const accessReport = loginReportAccess
// Clients actions
export const allClients = getAllClients
// Technical actions
export const allTechnical = getAllTechincal
export const technicalById = getTechincalById
export const logoutTech = logoutTechnical
// Parts actions
export const allParts = getAllParts
export const filterActionParts = getFilterParts
export const searchParts = getSearchParts
export const clearParts = clearFilterParts
// Stores actions
export const allStores = getAllStores
export const filterActionStores = getFilterStore
export const searchStores = getSearchStores
export const clearStores = clearFilterStores
// Quotes actions
export const allQuotes = getAllQuotes
export const filterActionQuotes = getFilterQuotes
export const searchQuotes = getSearchQuotes
export const clearQuotes = clearFilterQuotes
// PreBills actions
export const allPreBills = getAllPreBills
export const filterActionPreBills = getFilterPreBills
export const clearPreBills = clearFilterPreBills
export const searchPreBills = getSearchPreBills
// Services actions
export const allServices = getAllServices
export const filterActionServices = getFilterServices
export const clearServices = clearFilterServices
export const searchServices = getSearchServices
export const searchServicesByIds = getSearchServicesbyQuoteandPrebill
// Bills actions
export const allBills = getAllBills
export const filterActionBills = getFilterBills
export const clearBills = clearFilterBills
export const searchBills = getSearchBills
// Reports actions
export const allReports = getAllReports
export const filterActionReports = getFilterReports
export const clearReports = clearFilterReports
export const searchReports = getSearchReports
// Supplier actions
export const allSuppliers = getAllSuppliers
export const filterActionSuppliers = getFilterSuppliers
export const clearSuppliers = clearFilterSuppliers
export const searchSuppliers = getSearchSuppliers
// Counts actions
export const allCounts = getAllCounts
export const filterActionCounts = getFilterCounts
export const clearCounts = clearFilterCounts
export const searchCounts = getSearchCounts
// Demography actions
export const allCitys = getCitys
export const allDepartments = getDepartments
// Incomes actions
export const filterActionIncomes = getFilterIncomes
export const clearIncomes = clearFilterIncomes
export const allIncomes = getAllIncomes
// Bank actions
export const allBanks = getAllBanks
export const clearBanks = clearFilterBanks
export const filterActionBanks = getFilterBanks