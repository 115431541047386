import style from "./startReport.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as actions from "../../../redux/actions";
import axios from "axios";
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const StartReport = () => {
  // Importations
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Sumar 1 porque los meses van de 0 a 11
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    reset,
    watch,
    register,
    setValue,
  } = useForm();
  // Global States
  const allClients = useSelector((state) => state.allClients);
  const allCitys = useSelector((state) => state.allCitys);
  const allDepartments = useSelector((state) => state.allDepartments);
  const allStores = useSelector((state) => state.allStores);
/*   const alertSession = useSelector((state) => state.alertSession); */
  let client = watch("clientId");
  // Local states
  const [filterCity, setFilterCity] = useState([]);
  const [filterStore, setFilterStore] = useState([]);
  const [actualClient, setActualClient] = useState("");
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [formDisabled, setFormDisabled] = useState(null);
  const [otroInput, setOtroInput] = useState(null);
  // useEffect
  // useEffect(() => {
  //     const intervalId = setInterval(() => {
  //         setCurrentTime(new Date().toLocaleTimeString());
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  // }, []);
  useEffect(() => {
    setValue("date", formattedDate);
    setValue("timeIn", currentTime);
  }, [setValue, formattedDate, currentTime]);
  useEffect(() => {
    dispatch(actions.allStores());
    dispatch(actions.allClients());
    dispatch(actions.allCitys());
    dispatch(actions.allDepartments());
  }, []);
  useEffect(() => {
    setActualClient(client);
  }, [client]);
  // Funciones
  const handleChange = (e, allCitys) => {
    const departmentIdValue = e.target.value;
    const citysById = allCitys.filter(
      (city) => city.departmentId === parseInt(departmentIdValue)
    );
    setFilterCity(citysById);
  };
  const handleChangeStore = (e, allStores) => {
    const cityIdValue = e.target.value;
    const storesById = allStores.filter(
      (store) => store.cityId === parseInt(cityIdValue)
    );
    const storesByClient = storesById.filter(
      (store) => store.clientId === parseInt(actualClient)
    );
    setFilterStore(storesByClient);
  };
  const handleChangeClientId = (e) => {
    setValue("departmentId", "");
    trigger("departmentId");
    setValue("cityId", "");
    trigger("cityId");
    setValue("storeId", "");
    trigger("storeId");
  };
  const handleNewStoreChange = (e) => {
    if (e.target.value === "otro") {
      setOtroInput(true);
    } else {
      setOtroInput(false);
    }
  };
  const onSubmit = async (datos) => {
    datos.technician = id;
    try {
      if (otroInput && datos.otroStore !== "") {
        const storeNew = {
          name: datos.otroStore,
          clientId: datos.clientId,
          departmentId: datos.departmentId,
          cityId: datos.cityId,
          address: "Sin especificar",
          town: "Sin especificar",
        };
        setFormDisabled(true);
        const configStore = {
          method: "post",
          url: `${url}/stores/create`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: storeNew,
        };
        const respuesta = await axios(configStore);
        datos.storeId = respuesta.data.store.id;
        const config = {
          method: "post",
          url: `${url}/reports/create`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: datos,
        };
        const response = await axios(config);
        if (response.data) {
          if (response.data.success === true) {
            setConfirmRegistro(true);
            setTimeout(() => {
              setConfirmRegistro(null);
              reset();
              setFormDisabled(false);
              setTimeout(() => {
                navigate(`/reportes/step2/${response.data.created.id}`);
              }, 100);
            }, 3000);
          } else {
            setConfirmRegistro(false);
          }
        }
      } else {
        const config = {
          method: "post",
          url: `${url}/reports/create`,
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          data: datos,
        };
        const response = await axios(config);
        if (response.data) {
          if (response.data.success === true) {
            setConfirmRegistro(true);
            setTimeout(() => {
              setConfirmRegistro(null);
              reset();
              setFormDisabled(false);
              setTimeout(() => {
                // Supongamos que `response.data.created.id` es el ID que necesitas para construir la URL
                const url = `/reportes/step2/${response.data.created.id}`;
                // Abrir la URL en una nueva pestaña
                //window.open(url, '_blank');
                navigate(`/reportes/step2/${response.data.created.id}`);
              }, 100);
            }, 3000);
          } else {
            setConfirmRegistro(false);
          }
        }
      }
    } catch (error) {
      console.error("Error al crear reporte:", error);
      setFormDisabled(false);
      setConfirmRegistro(false);

            // if(error.response.status === 401) {
            //     dispatch({
            //         type: ALERT_SESSION,
            //         payload: true
            //     })

            //     setTimeout(() => {
            //         localStorage.clear('globalState')
            //         window.location.replace(`${urlFront}/inicio`)
            //     }, [3000])
                
            // }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            {/* {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>} */}
            <div className={style.divForm2}>
                {/* <img className={style.logo2} src="https://res.cloudinary.com/dfmsezslx/image/upload/v1700522308/TECDE/RefriActive_Img_zfhpl2.png" alt="LogoRefriActive" /> */}
                <h2 className={style.tituloSeccion}>Registro de Reporte</h2>
                <p className={style.subtitulo}><strong>Sección #1</strong></p>
                <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={style.rowform}>
                        <div className={style.divDatos}>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="date">Fecha de servicio</label>
                                <div className={style.divInput}>
                                    <Controller
                                        name="date"
                                        control={control}
                                        // disabled={true}
                                        defaultValue={formattedDate}
                                        render={({ field }) => (
                                            <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="rum"># RUM</label>
                                <div className={style.divInput}>
                                    <Controller
                                        name="rum"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: { value: 20, message: 'Maximo 20 caracteres' }, minLength: { value: 1, message: 'Minimo 1 caracteres' } }}
                                        render={({ field }) => (
                                            <input type="text" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("rum"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.rum && <p className={style.errorText}>{errors.rum.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="clientId"> Cliente </label>
                                <div className={style.divInput}>
                                    <Controller name="clientId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el cliente' }}
                                        render={({ field }) => (
                                            <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId"); handleChangeClientId(e) }}>
                                                <option value="" disabled> Seleccione el cliente </option>
                                                {allClients !== undefined ? allClients.map((client) => (
                                                    <option key={client.id} value={client.id}>
                                                        {client.name}</option>)) : null}
                                            </select>)} />
                                    <div className={style.errorMenssage}>
                                        {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="departmentId"> Departamento </label>
                                <div className={style.divInput}>
                                    <Controller name="departmentId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el depertamento' }}
                                        render={({ field }) => (
                                            <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("departmentId"); handleChange(e, allCitys) }}>
                                                <option value="" disabled> Seleccione el departamento </option>
                                                {allDepartments !== undefined ? allDepartments.map((department) => (
                                                    <option key={department.id} value={department.id}>
                                                        {department.department}</option>)) : null}
                                            </select>)} />
                                    <div className={style.errorMenssage}>
                                        {errors.departmentId && <p className={style.errorText}>{errors.departmentId.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                <div className={style.divInput}>
                                    <Controller name="cityId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione la ciudad' }}
                                        render={({ field }) => (
                                            <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                <option value="" disabled> Seleccione la ciudad </option>
                                                {filterCity !== undefined ? filterCity.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.city}</option>)) : null}
                                            </select>)} />
                                    <div className={style.errorMenssage}>
                                        {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="storeId"> Almacen </label>
                                <div className={style.divInput}>
                                    <Controller name="storeId"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Seleccione el almacen' }}
                                        render={({ field }) => (
                                            <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId"); handleNewStoreChange(e) }}>
                                                <option value="" disabled> Seleccione el almacen </option>
                                                <option value="otro" > Nuevo almacen </option>
                                                {filterStore.map((store) => (
                                                    <option key={store.id} value={store.id}>
                                                        {store.name}</option>))}
                                            </select>)} />
                                    <div className={style.errorMenssage}>
                                        {errors.storeId && <p className={style.errorText}>{errors.storeId.message}</p>}
                                    </div>
                                </div>
                            </div>
                            {otroInput ? (<div className={style.campoDiv}>
                                <label className={style.label} htmlFor="otroStore">Almacen a crear</label>
                                <div className={style.divInput}>
                                    <Controller
                                        name="otroStore"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'Este campo es obligatorio', maxLength: { value: 100, message: 'Maximo 100 caracteres' }, minLength: { value: 1, message: 'Minimo 1 caracteres' } }}
                                        render={({ field }) => (
                                            <input type="text" className={style.input} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("otroStore"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.otroStore && <p className={style.errorText}>{errors.otroStore.message}</p>}
                                    </div>
                                </div>
                            </div>) : (null)}
                            <div className={style.divCampo}>
                                <label className={style.label} htmlFor="timeIn">Hora de ingreso</label>
                                <div className={style.divInput}>
                                    <Controller
                                        name="timeIn"
                                        control={control}
                                        defaultValue={currentTime}
                                        rules={{ required: 'Este campo es obligatorio' }}
                                        render={({ field }) => (
                                            <input type="time" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("timeIn"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.timeIn && <p className={style.errorText}>{errors.timeIn.message}</p>}
                                    </div>
                                </div>
                                {/* <div className={style.divInput}>
                                    <Controller
                                        name="timeIn"
                                        control={control}
                                        disabled={true}
                                        defaultValue={currentTime}
                                        render={({ field }) => (
                                            <input type="text" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("timeIn"); }} />)} />
                                    <div className={style.errorMenssage}>
                                        {errors.timeIn && <p className={style.errorText}>{errors.timeIn.message}</p>}
                                    </div>
                                </div> */}
              </div>
              <div className={style.divCampo}>
                <label className={style.label} htmlFor="serviceType">
                  {" "}
                  Tipo de servicio{" "}
                </label>
                <div className={style.divInput}>
                  <Controller
                    name="serviceType"
                    control={control}
                    defaultValue={""}
                    rules={{ required: "Seleccione el tipo de servicio" }}
                    render={({ field }) => (
                      <select
                        className={style.select}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("serviceType");
                        }}
                      >
                        <option value="" disabled>
                          {" "}
                          Seleccione el tipo{" "}
                        </option>
                        <option value="Correctivo"> Correctivo </option>
                        <option value="Preventivo"> Preventivo </option>
                        <option value="Repuestos">
                          {" "}
                          Instalación de repuestos{" "}
                        </option>
                        <option value="Stand By"> Stand By </option>
                        <option value="Hallazgos"> Hallazgos </option>
                        <option value="Visita técnica"> Visita técnica </option>
                      </select>
                    )}
                  />
                  <div className={style.errorMenssage}>
                    {errors.serviceType && (
                      <p className={style.errorText}>
                        {errors.serviceType.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.divCampo}>
                <label className={style.label} htmlFor="type">
                  {" "}
                  Tipo de reporte{" "}
                </label>
                <div className={style.divInput}>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={""}
                    rules={{ required: "Seleccione el almacen" }}
                    render={({ field }) => (
                      <select
                        className={style.select}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("type");
                        }}
                      >
                        <option value="" disabled>
                          {" "}
                          Seleccione el tipo{" "}
                        </option>
                        <option value={true}> Servicio </option>
                        <option value={false}> Proyecto </option>
                      </select>
                    )}
                  />
                  <div className={style.errorMenssage}>
                    {errors.type && (
                      <p className={style.errorText}>{errors.type.message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="type">
                {" "}
                Tipo de reporte{" "}
              </label>
              <div className={style.divInput}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Seleccione el tipo de reporte" }}
                  render={({ field }) => (
                    <select
                      className={style.select}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("type");
                      }}
                    >
                      <option value="" disabled>
                        {" "}
                        Seleccione el tipo{" "}
                      </option>
                      <option value={true}> Servicio </option>
                      <option value={false}> Proyecto </option>
                    </select>
                  )}
                />
                <div className={style.errorMenssage}>
                  {errors.type && (
                    <p className={style.errorText}>{errors.type.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={formDisabled}
            className={style.formbutton}
          >
            Iniciar servicio
          </button>
          {confirmRegistro === true ? (
            <>
              <p className={style.positivo}>Reporte iniciado con éxito</p>
            </>
          ) : confirmRegistro === false ? (
            <>
              <p className={style.negativo}>
                {" "}
                No se pudo registrar, vuelve a intentarlo
              </p>
            </>
          ) : null}
          <Link to="/reportes">
            <button type="button" className={style.backbutton}>
              Volver
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};
export default StartReport;
