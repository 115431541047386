import style from "./createBill.module.css"
import NavBar from "../../../components/NavBar/NavBar"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useForm, Controller } from 'react-hook-form';
import * as actions from "../../../redux/actions"
import axios from "axios"
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";


const CreateBillByService = () => {
    // Importations
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { report } = useParams()
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const { handleSubmit, control, formState: { errors }, trigger, reset, watch, register, setValue } = useForm()
    // Global States
    const userData = useSelector((state) => state.userLoginNow)
    const allClients = useSelector((state) => state.allClients)
    const allParts = useSelector((state) => state.allParts)
    const allCitys = useSelector((state) => state.allCitys)
    const allDepartments = useSelector((state) => state.allDepartments)
    const allStores = useSelector((state) => state.allStores)
    const alertSession = useSelector((state) => state.alertSession)
    let client = watch("clientId")
    let city = watch("cityId")
    let store = watch("storeId")
    // Local states
    const [formDisabled, setFormDisabled] = useState(null)
    const [filterStore, setFilterStore] = useState([])
    const [actualClient, setActualClient] = useState("")
    const [actualCity, setActualCity] = useState("")
    const [actualStore, setActualStore] = useState("")
    const [quoteList, setQuoteList] = useState([])
    const [countItem, setCountItem] = useState(1)
    const [confirmRegistro, setConfirmRegistro] = useState(null);
    const [actualQuote, setActualQuote] = useState({})
    const [actualService, setActualService] = useState({})
    const [isDisabled, setIsDisabled] = useState(true);
    // Get de quote
    const handleChangeStore = () => {
        const storesById = allStores.filter((store) => store.cityId === parseInt(city))
        const storesByClient = storesById.filter((store) => store.clientId === parseInt(actualClient))
        setFilterStore(storesByClient)
    }
    const handleChangeClientId = (e) => {
        setValue('departmentId', "");
        trigger('departmentId');
        setValue('cityId', "");
        trigger('cityId');
        setValue('storeId', "");
        trigger('storeId');
    };
    const getStoreName = (allStores, storeId) => {
        const store = allStores.find((store) => store.id === storeId);
        return store ? store.name : 'Tienda desconocido';
      };
      const getCityName = (allCitys, cityId) => {
        const city = allCitys && allCitys.find((city) => city.id === cityId);
        return city ? city.city : 'Ciudad Desconocida';
      };
    useEffect(() => {
        const getActualService = async (report) => {
            try {
                setFormDisabled(true);
                const config = {
                    method: 'get',
                    url: `${url}/serviceReport/${report}`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                };
                const response = await axios(config)
                if (response.data) {
                    if (response.data.success === true) {
                        const quote = response.data.serviceReport
                        setActualService(quote)
                        setQuoteList(quote.data.quoteParts)
                    }
                }
            } catch (error) {
                console.error("Error al crear tienda:", error);
                setFormDisabled(false);
                setConfirmRegistro(false)
                if(error.response.status === 401) {
                    dispatch({
                        type: ALERT_SESSION,
                        payload: true
                    })
    
                    setTimeout(() => {
                        localStorage.clear('globalState')
                        window.location.replace(urlFront)
                    }, [3000])
                    
                }  
            }
        }
        if(id != undefined) {
            getActualService(id)
        }
        
    }, [])
    useEffect(() => {
        setValue('preBillId', "0000");
        setValue("paymentMeth", actualService.data && actualService.data.paymentMeth)
        setValue("clientId", actualService && actualService.clientId)
        setValue("cityId", actualService.data && actualService.data.cityId)
        setValue("storeId", actualService.data && actualService.data.storeId)
        setValue("reportRum", actualService.data && actualService.data.reportRum)
        setValue("actaEntrega", actualService.data && actualService.data.actaEntrega)
        setValue("saleOrder", actualService.saleOrder)
    }, [actualQuote, actualService, setValue]);
    useEffect(() => {
        // Calcula el máximo valor de 'item'
        const maxItem = Math.max(...quoteList.map(obj => obj.item), 0);
        setCountItem(maxItem + 1);
    }, [quoteList]);
    //UseEffect
    useEffect(() => {
        dispatch(actions.allStores())
        dispatch(actions.allClients())
        dispatch(actions.allParts())
        dispatch(actions.allCitys())
    }, [])
    useEffect(() => {
        setActualClient(client)
        setActualCity(city)
        setActualStore(store)
    }, [client, city, store])
    // Funciones
    const onSubmit = async (data) => {
        const datos = {
            clientId: actualService.clientId,
            saleOrder: data.saleOrder,
            data: actualService.data
        }
        try {
            setFormDisabled(true);
            const config = {
                method: 'post',
                url: `${url}/bills/create`,
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                data: datos
            };
            const response = await axios(config)
            if (response.data) {
                if (response.data.success === true) {
                    try {
                        const { data } = response
                        const updateInfo = {
                            bills: [data.created.id]
                        }
                        const config = {
                            method: 'patch',
                            url: `${url}/serviceReport/update/${id}`,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            withCredentials: true,
                            data: updateInfo
                        };
                        const respuesta = await axios(config)
                        if (respuesta.data) {
                            if (respuesta.data.success === true) {
                                try {
                                    const { data } = response
                                    const billToSheet = data.created.id
                                    const config = {
                                        method: 'get',
                                        url: `${url}/createSheet?name=${billToSheet}`,
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        withCredentials: true,
                                    };
                                    const respuestaSheet = await axios(config)
                                    if (respuestaSheet.status === 201) {
                                        setConfirmRegistro(true)
                                        setTimeout(() => {
                                            setConfirmRegistro(null);
                                            reset();
                                            setFormDisabled(false);
                                            setTimeout(() => {
                                                navigate("/bills");
                                            }, 100);
                                        }, 2000);
                                    }
                                } catch (error) {
                                    console.error("Error al crear hoja de excel:", error);
                                    setFormDisabled(false);
                                    setConfirmRegistro(false)
                                }
                            }
                            else {
                                setConfirmRegistro(false)
                            }
                        }
                    } catch (error) {
                        console.error("Error al actualizar servicio:", error);
                        setFormDisabled(false);
                        setConfirmRegistro(false)

                        if(error.response.status === 401) {
                            dispatch({
                                type: ALERT_SESSION,
                                payload: true
                            })
            
                            setTimeout(() => {
                                localStorage.clear('globalState')
                                window.location.replace(urlFront)
                            }, [3000])
                            
                        }  
                    }
                }
                else {
                    setConfirmRegistro(false)
                }
            }
        } catch (error) {
            console.error("Error al crear factura:", error);
            setFormDisabled(false);
            setConfirmRegistro(false)
            if(error.response.status === 401) {
                dispatch({
                    type: ALERT_SESSION,
                    payload: true
                })

                setTimeout(() => {
                    localStorage.clear('globalState')
                    window.location.replace(urlFront)
                }, [3000])
                
            }  
        }
    }
    // Renderizado
    return (
        <div className={style.divContenedor}>
            <div className={style.divNavBar}>
                <NavBar />
            </div>
            <div className={style.divPrincipal}>
            {alertSession && <AlertSession text1="Su sesión ha vencido" text2="Por favor, vuelva a iniciar sesión" disabledBtn={false}/>}
                <h1 className={style.tituloSeccion}>Facturas</h1>
                <h4 className={style.subtitulo}>Crear factura </h4>
                <h5 className={style.subtitulo}>A partir de Servicio # {id}</h5>
                <div className={style.divForm}>
                    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={style.rowform}>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="saleOrder">Orden de compra</label>
                                    <div className={style.divInput}>
                                        <Controller name="saleOrder"
                                            control={control}
                                            defaultValue={actualService.saleOrder}
                                            disabled={isDisabled}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" min="0" {...field} onChange={(e) => { field.onChange(e); trigger("saleOrder"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.saleOrder && <p className={style.errorText}>{errors.saleOrder.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="preBillId">Número de prefactura</label>
                                    <div className={style.divInput}>
                                        <Controller name="preBillId"
                                            control={control}
                                            defaultValue={id}
                                            disabled={isDisabled}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("preBillId"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.preBillId && <p className={style.errorText}>{errors.preBillId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="reportRum">RUM</label>
                                    <div className={style.divInput}>
                                        <Controller name="reportRum"
                                            control={control}
                                            defaultValue={actualService.data && actualService.data.reportRum}
                                            disabled={isDisabled}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="text" {...field} onChange={(e) => { field.onChange(e); trigger("reportRum"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.reportRum && <p className={style.errorText}>{errors.reportRum.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="actaEntrega">Acta de entrega</label>
                                    <div className={style.divInput}>
                                        <Controller name="actaEntrega"
                                            control={control}
                                            defaultValue={actualService.data && actualService.data.actaEntrega}
                                            disabled={isDisabled}
                                            rules={{ required: 'Este campo es obligatorio', maxLength: { value: 15, message: 'Máximo 15 caracteres' }, minLength: { value: 4, message: 'Minimo 4 caracteres' } }}
                                            render={({ field }) => (
                                                <input className={style.input} type="number" {...field} onChange={(e) => { field.onChange(e); trigger("actaEntrega"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.actaEntrega && <p className={style.errorText}>{errors.actaEntrega.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="date">Fecha</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue={formattedDate}
                                            rules={{ required: 'Este campo es obligatorio' }}
                                            render={({ field }) => (
                                                <input type="date" className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("date"); }} />)} />
                                        <div className={style.errorMenssage}>
                                            {errors.date && <p className={style.errorText}>{errors.date.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.divDatos}>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="clientId"> Cliente </label>
                                    <div className={style.divInput}>
                                        <Controller name="clientId"
                                            control={control}
                                            defaultValue={actualService.clientId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione el cliente' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("clientId"); handleChangeClientId(e) }}>
                                                    <option value="" disabled> Seleccione el cliente </option>
                                                    {allClients !== undefined ? allClients.map((client) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.name}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.clientId && <p className={style.errorText}>{errors.clientId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="cityId"> Ciudad </label>
                                    <div className={style.divInput}>
                                        <Controller name="cityId"
                                            control={control}
                                            defaultValue={actualService.data && actualService.data.cityId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione la ciudad' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("cityId"); handleChangeStore(e, allStores) }}>
                                                    <option value="" disabled> Seleccione la ciudad </option>
                                                    {allCitys !== undefined ? allCitys.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.city}</option>)) : null}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.cityId && <p className={style.errorText}>{errors.cityId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="storeId"> Almacen </label>
                                    <div className={style.divInput}>
                                        <Controller name="storeId"
                                            control={control}
                                            defaultValue={actualService.data && actualService.data.storeId}
                                            disabled={isDisabled}
                                            rules={{ required: 'Seleccione el almacen' }}
                                            render={({ field }) => (
                                                <select disabled={isDisabled} className={style.select} {...field} onChange={(e) => { field.onChange(e); trigger("storeId") }}>
                                                    <option value="" disabled> Seleccione el almacen </option>
                                                    {allStores.map((store) => (
                                                        <option key={store.id} value={store.id}>
                                                            {store.name}</option>))}
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.storeId && <p className={style.errorText}>{errors.storeId.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.divCampo}>
                                    <label className={style.label} htmlFor="paymentMeth">Forma de Pago</label>
                                    <div className={style.divInput}>
                                        <Controller
                                            name="paymentMeth"
                                            control={control}
                                            defaultValue={actualService.data && actualService.data.paymentMeth}
                                            rules={{ required: 'Seleccione su forma de pago' }}
                                            render={({ field }) => (
                                                <select className={style.select} {...field} value={field.value} onChange={(e) => { field.onChange(e); trigger("paymentMeth"); }}>
                                                    <option value="" disabled>Seleccione la forma de pago</option>
                                                    <option value="A convenir">A convenir</option>
                                                    <option value="Contado">Contado</option>
                                                    <option value="Crédito hasta 30 días">Crédito a 30 días</option>
                                                    <option value="Crédito 45 días">Crédito a 45 días</option>
                                                    <option value="Crédito 60 días">Crédito a 60 días</option>
                                                    <option value="Crédito 90 días">Crédito a 90 días</option>
                                                </select>)} />
                                        <div className={style.errorMenssage}>
                                            {errors.paymentMeth && <p className={style.errorText}>{errors.paymentMeth.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.divDatos}>
                            <h3 className={style.subtitulo}>Resumen de factura</h3>
                            <div className={style.divTabla}>
                                <table className={style.table}>
                                    <tr className={style.tr}>
                                        <th className={style.th}>Item</th>
                                        <th className={style.th}>Descripción</th>
                                        <th className={style.th}>Unidad</th>
                                        <th className={style.th}>Cantidad</th>
                                        <th className={style.th}>Valor unitario</th>
                                        <th className={style.th}>Valor total</th>
                                        <th className={style.thres}>Descripción</th>
                                        <th className={style.thres}>Cantidad</th>
                                        <th className={style.thres}>Valor unitario</th>
                                    </tr>
                                    {quoteList.map((orden) => (
                                        <tr className={style.tr} key={orden.item}>
                                            <td className={style.td}>{orden.item}</td>
                                            <td className={style.td}>{orden.description}</td>
                                            <td className={style.td}>{orden.unidad}</td>
                                            <td className={style.td}>{orden.quantity}</td>
                                            <td className={style.td}>$ {orden.costUnit.toLocaleString()}</td>
                                            <td className={style.td}>$ {orden.totalCost.toLocaleString()}</td>
                                            <td className={style.tdres}>{orden.description}</td>
                                            <td className={style.tdres}>{orden.quantity}</td>
                                            <td className={style.tdres}>$ {orden.costUnit.toLocaleString()}</td>
                                        </tr>))}
                                </table>
                            </div>
                        </div>
                        <button type="submit" className={style.formbutton}>Crear factura</button>
                        {confirmRegistro === true ? (<><p className={style.positivo}>Factura creada con éxito</p></>) : (confirmRegistro === false ? (<><p className={style.negativo} > No se pudo registrar, vuelve a intentarlo</p></>) : (null))}
                        <Link to="/bills">
                            <button type="button" className={style.backbutton}>Volver</button>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    )

}
export default CreateBillByService