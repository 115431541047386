import style from "./createServices.module.css";
import NavBar from "../../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as actions from "../../../redux/actions";
import axios from "axios";
import { url, urlFront } from "../../../App";
import { ALERT_SESSION } from "../../../redux/action-types";
import AlertSession from "../../../components/AlertSession/AlertSession";

const ViewService = () => {
  // Importations
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentDate = new Date();
  const { watch } = useForm();
  // Global States
  const allClients = useSelector((state) => state.allClients);
  const alertSession = useSelector((state) => state.alertSession);
  let client = watch("clientId");
  // Local states
  const [formDisabled, setFormDisabled] = useState(null);
  const [actualClient, setActualClient] = useState("");
  const [quoteList, setQuoteList] = useState([]);
  const [countItem, setCountItem] = useState(1);
  const [confirmRegistro, setConfirmRegistro] = useState(null);
  const [actualQuote, setActualQuote] = useState({});
  const [actualPreBill, setActualPreBill] = useState({});
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás, equivalente a history.goBack()
  };
  // Get de quote
  const getActualQuote = async (preBillsId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: "get",
        url: `${url}/serviceReport/${preBillsId}`,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios(config);
      if (response.data) {
        if (response.data.success === true) {
          const preBill = response.data.serviceReport;
          setActualQuote(preBill);

          if (preBill.preBills !== null) {
            getActualPreBill(preBill.preBills[0]);
          }
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false);

      if (error.response.status === 401) {
        dispatch({
          type: ALERT_SESSION,
          payload: true,
        });

        setTimeout(() => {
          localStorage.clear("globalState");
          window.location.replace(urlFront);
        }, [3000]);
      }
    }
  };
  const getActualPreBill = async (preBillsId) => {
    try {
      setFormDisabled(true);
      const config = {
        method: "get",
        url: `${url}/preBills/${preBillsId}`,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios(config);
      if (response.data) {
        if (response.data.success === true) {
          const preBill = response.data.byId;
          setActualPreBill(preBill);
        }
      }
    } catch (error) {
      console.error("Error al crear tienda:", error);
      setFormDisabled(false);
      setConfirmRegistro(false);

      if (error.response.status === 401) {
        dispatch({
          type: ALERT_SESSION,
          payload: true,
        });

        setTimeout(() => {
          localStorage.clear("globalState");
          window.location.replace(urlFront);
        }, [3000]);
      }
    }
  };
  useEffect(() => {
    getActualQuote(id);
    //     if(actualQuote !== undefined && actualQuote.preBills && actualQuote.preBills !== undefined ) {
    //         getActualPreBill(actualQuote?.preBills[0])
    //     }
    //     getActualPreBill(actualQuote !== undefined && actualQuote.preBills && actualQuote.preBills !== undefined && actualQuote.preBills[0])
  }, [dispatch]);
  useEffect(() => {
    // Calcula el máximo valor de 'item'
    const maxItem = Math.max(...quoteList.map((obj) => obj.item), 0);
    setCountItem(maxItem + 1);
  }, [quoteList]);
  //UseEffect
  useEffect(() => {
    dispatch(actions.allStores());
    dispatch(actions.allClients());
    dispatch(actions.allParts());
  }, [dispatch]);
  useEffect(() => {
    setActualClient(client);
  }, [client]);
  // Funciones
  const getClientName = (allClients, clientId) => {
    const client = allClients.find((client) => client.id === clientId);
    return client ? client.name : "Cliente desconocido";
  };
  // Renderizado
  return (
    <div className={style.divContenedor}>
      <div className={style.divNavBar}>
        <NavBar />
      </div>
      <div className={style.divPrincipal}>
        {alertSession && (
          <AlertSession
            text1="Su sesión ha vencido"
            text2="Por favor, vuelva a iniciar sesión"
            disabledBtn={false}
          />
        )}
        <h1 className={style.tituloSeccion}>Servicios</h1>
        <h4 className={style.subtitulo}>Servicio #0{actualQuote.id}</h4>
        <h6 className={style.label}>
          {actualQuote.old === true ? "Servicio antiguo" : ""}
        </h6>
        {actualQuote.old !== true ? (
          <div className={style.divForm}>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>Número de reporte</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.reports !== undefined &&
                    actualQuote?.reports[0]}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Fecha</label>
                <p className={style.input}>
                  {" "}
                  {actualPreBill &&
                    actualPreBill.date !== undefined &&
                    actualPreBill.date}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Cliente</label>
                <p className={style.input}>
                  {getClientName(allClients, actualQuote.clientId)}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Acta de entrega</label>
                <p className={style.input}>
                  {" "}
                  {actualPreBill &&
                    actualPreBill.actaEntrega !== undefined &&
                    actualPreBill.actaEntrega}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Total</label>
                <p className={style.input}>
                  $
                  {actualPreBill &&
                    actualPreBill.total !== undefined &&
                    actualPreBill.total.toLocaleString()}
                </p>
              </div>
            </div>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>RUM</label>
                <p className={style.input}>
                  {" "}
                  {actualPreBill &&
                    actualPreBill.reportRum !== undefined &&
                    actualPreBill.reportRum}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Forma de pago</label>
                <p className={style.input}>
                  {actualPreBill &&
                    actualPreBill.paymentMeth !== undefined &&
                    actualPreBill.paymentMeth}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Orden de compra</label>
                <p className={style.input}># {actualQuote.saleOrder}</p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Identificador</label>
                <p className={style.input}># {actualQuote.codeIdentificator}</p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Estado de servicio</label>
                <p className={style.input}>
                  {actualQuote.state === true ? "Activo" : "Cerrado"}
                </p>
              </div>
            </div>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>Estado de pago</label>
                <p className={style.input}>
                  {actualQuote.paymentState === true
                    ? "Confirmado"
                    : "Pendiente"}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Descripción de estado</label>
                <p className={style.input}>
                  {actualQuote.stateDescription === " "
                    ? "Descripción del estado"
                    : actualQuote.stateDescription}
                </p>
              </div>
              <div className={style.divDatos}>
                <label className={style.label}>Historial facturas</label>
                <div className={style.divTabla}>
                  <table className={style.table}>
                    <tr className={style.tr}>
                      <th className={style.th}>#</th>
                    </tr>
                    {actualQuote.bills
                      ? actualQuote.bills.map((orden) => (
                          <tr className={style.tr} key={orden}>
                            <Link to={`/bills/view/${orden}`}>
                              <td className={style.td}>{orden}</td>
                            </Link>
                          </tr>
                        ))
                      : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style.divForm}>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>Número de reporte</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.data &&
                    actualQuote.data.reportId &&
                    actualQuote.data.reportId !== undefined &&
                    actualQuote.data.reportId !== null &&
                    actualQuote.data.reportId}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Fecha</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.data &&
                    actualQuote.data.aplicationDate !== undefined &&
                    actualQuote.data.aplicationDate}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Cliente</label>
                <p className={style.input}>
                  {actualQuote &&
                    actualQuote.data &&
                    getClientName(allClients, actualQuote.clientId)}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Acta de entrega</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.data &&
                    actualQuote.data.actaEntrega !== undefined &&
                    actualQuote.data.actaEntrega}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Total</label>
                <p className={style.input}>
                  $
                  {actualQuote &&
                    actualQuote.total !== undefined &&
                    actualQuote.total.toLocaleString()}
                </p>
              </div>
            </div>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>RUM</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.data &&
                    actualQuote.data.reportRum !== undefined &&
                    actualQuote.data.reportRum}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Forma de pago</label>
                <p className={style.input}>
                  {" "}
                  {actualQuote &&
                    actualQuote.data &&
                    actualQuote.data.paymentMeth !== undefined &&
                    actualQuote.data.paymentMeth}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Orden de compra</label>
                <p className={style.input}>
                  # {actualQuote && actualQuote.saleOrder}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Identificador</label>
                <p className={style.input}>
                  # {actualQuote && actualQuote.codeIdentificator}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Estado de servicio</label>
                <p className={style.input}>
                  {actualQuote && actualQuote.state === true
                    ? "Activo"
                    : "Cerrado"}
                </p>
              </div>
            </div>
            <div className={style.divDatos}>
              <div className={style.divCampo}>
                <label className={style.label}>Estado de pago</label>
                <p className={style.input}>
                  {actualQuote &&
                  actualQuote.data &&
                  actualQuote.paymentState === true
                    ? "Confirmado"
                    : "Pendiente"}
                </p>
              </div>
              <div className={style.divCampo}>
                <label className={style.label}>Descripción de estado</label>
                <p className={style.input}>
                  {actualQuote &&
                  actualQuote.data &&
                  actualQuote.stateDescription === " "
                    ? "Descripción del estado"
                    : actualQuote.stateDescription}
                </p>
              </div>
              <div className={style.divDatos}>
                <label className={style.label}>Historial facturas</label>
                <div className={style.divTabla}>
                  <table className={style.table}>
                    <tr className={style.tr}>
                      <th className={style.th}>#</th>
                    </tr>
                    {actualQuote.bills
                      ? actualQuote.bills.map((orden) => (
                          <tr className={style.tr} key={orden}>
                            <Link to={`/bills/view/${orden}`}>
                              <td className={style.td}>{orden}</td>
                            </Link>
                          </tr>
                        ))
                      : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        <Link
          to={`/quotes/view/${
            actualQuote && actualQuote.quotes
              ? actualQuote.quotes !== undefined && actualQuote.quotes[0]
              : 0
          }`}
        >
          <button
            type="button"
            disabled={
              actualQuote.quotes
                ? actualQuote.quotes === null ||
                  actualQuote.quotes === undefined ||
                  actualQuote.quotes[
                    actualQuote.quotes && actualQuote.quotes.length - 1
                  ] === 0 ||
                  actualQuote.quotes.length === 0
                : true
            }
            className={style.backbutton}
          >
            Ver cotización{" "}
            <strong>{`#${
              actualQuote &&
              actualQuote.quotes &&
              actualQuote.quotes !== undefined &&
              actualQuote.quotes.length > 0
                ? actualQuote.quotes[0]
                : ""
            }`}</strong>
          </button>
        </Link>
        <Link
          to={`/preBills/view/${
            actualQuote &&
            actualQuote.preBills &&
            actualQuote.preBills !== undefined &&
            actualQuote.preBills[0]
          }`}
        >
          <button
            disabled={
              actualQuote.preBills
                ? actualQuote.preBills === null ||
                  actualQuote.preBills === undefined ||
                  actualQuote.preBills[
                    actualQuote.preBills && actualQuote.preBills.length - 1
                  ] === 0 ||
                  actualQuote.preBills.length === 0
                : true
            }
            type="button"
            className={style.backbutton}
          >
            Ver prefactura{" "}
            <strong>{`#${
              actualQuote &&
              actualQuote.preBills &&
              actualQuote.preBills !== undefined &&
              actualQuote.preBills.length > 0
                ? actualQuote.preBills[0]
                : ""
            }`}</strong>
          </button>
        </Link>
        <Link
          to={`/bills/view/${
            actualQuote &&
            actualQuote.bills !== null &&
            actualQuote.bills !== undefined &&
            actualQuote.bills[actualQuote.bills.length - 1]
          }`}
        >
          <button
            disabled={
              actualQuote.bills === null ||
              actualQuote.bills === undefined ||
              actualQuote.bills[actualQuote.bills.length - 1] === 0 ||
              actualQuote.bills.length === 0
            }
            type="button"
            className={style.backbutton}
          >
            Ver factura{" "}
            <strong>{`#${
              actualQuote &&
              actualQuote.bills !== null &&
              actualQuote.bills !== undefined &&
              actualQuote.bills.length > 0
                ? actualQuote.bills[actualQuote.bills.length - 1]
                : ""
            }`}</strong>
          </button>
        </Link>
        <Link to={`/services/${actualQuote.id}`}>
          <button type="button" className={style.backbutton}>
            Editar servicio
          </button>
        </Link>
        <button
          type="button"
          className={style.backbutton}
          onClick={handleGoBack}
        >
          Volver
        </button>
      </div>
    </div>
  );
};
export default ViewService;
